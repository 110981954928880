@import "../../../styles/2-tools/tools.media-query.scss";
@import "../../../styles/2-tools/tools.rem.scss";

.Modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1050;
	display: none;
	overflow: hidden;
	-webkit-overflow-scrolling: touch;
	outline: 0;
	color: var(--color-black);

	&___fade {
		transition: opacity 0.15s linear;
		opacity: 0;
		.Modal_dialog {
			transform: translateY(-50px);
			transition: (0.3s ease-out);
		}
	}
	&___show {
		opacity: 1;
		.Modal_dialog {
			transform: none;
		}
	}
	&___visible {
		display: block;
	}
	&___hidden {
		display: none;
	}
}

.Modal_open {
	overflow: hidden;
	.Modal {
		overflow-x: hidden;
		overflow-y: hidden;
	}
}

.Modal_dialog {
	position: relative;
	width: auto;
	margin: var(--spacing--xl) auto;
	padding-left: var(--spacing--2xl);
	padding-right: var(--spacing--2xl);
	@include mq("md", max) {
		padding-left: var(--spacing--xl);
		padding-right: var(--spacing--xl);
	}
	@include mq("sm", max) {
		padding-left: var(--spacing--md);
		padding-right: var(--spacing--md);
	}
	&___small {
		max-width: var(--width-text-max);
	}
	&___large {
		max-width: pxToRem(1100);
	}

	@include mq("md") {
		:global(.sop-app) & {
			max-width: unset;
			width: 773px;
		}
	}
}
.Modal_header {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}
.Modal_headerHeading {
	margin-top: var(--spacing--md);
	margin-left: var(--spacing--lg);

	:global(.sop-app) & {
		margin-top: var(--spacing--lg);
	}
}
.Modal_headerClose {
	min-width: 2.5rem;
	width: 2.5rem;
	height: 2.5rem;
	background-color: var(--color-primary);
	background-position: center center;
	background-repeat: no-repeat;
	opacity: 1;

	svg {
		width: 2rem;
		height: 2rem;
	}
}
.Modal_body {
	padding: var(--spacing--lg);
	display: flex;
	flex-direction: column;
	max-height: 80vh;
	overflow: auto;

	&___search {
		overflow: unset;
	}
}

.Modal_content {
	position: relative;
	background-color: var(--color-white);
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.2);
	width: 100%;
	pointer-events: auto;

	&___withIcon {
		@include mq("md") {
			display: flex;
		}

		:global(.sop-app) & {
			display: flex;
		}
	}
}

.Modal_contentContainer {
	:global(.sop-app) & {
		width: 100%;
	}
}

.Modal_helpButton {
	margin-top: var(--spacing--md);
	margin-left: var(--spacing--md);

	:global(.sop-app) & {
		margin-top: var(--spacing--lg);
	}
}

.Modal_backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1040;
	width: 100%;
	height: 100%;
	background-color: var(--color-black);
	&___show {
		opacity: 0.5;
	}
	&___fade {
		transition: opacity 0.15s linear;
	}
	&:not(.Modal_backdrop___show) {
		opacity: 0;
	}

	&___hidden {
		display: none;
	}
}
