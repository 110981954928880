@import "../../../styles/2-tools/tools.typography-mixins.scss";

.Heading {
	&_wrapper {
		max-width: var(--width-text-max);
		:global(.sop-app) & {
			word-break: break-all;
		}
	}

	&___xs {
		@include heading--xs;
		&.Heading___margined {
			margin: var(--spacing--lg) 0 var(--spacing--2xs);
		}
	}

	&___sm {
		@include heading--sm;
		&.Heading___margined {
			margin: var(--spacing--lg) 0 var(--spacing--2xs);
		}
	}

	&___md {
		@include heading--md;
		&.Heading___margined {
			margin: var(--spacing--lg) 0 var(--spacing--xs);
		}
	}

	&___lg {
		@include heading--lg;
		&.Heading___margined {
			margin: var(--spacing--sm) 0 var(--spacing--sm);
		}
	}

	&___xl {
		@include heading--xl;
		&.Heading___margined {
			margin: var(--spacing--sm) 0 var(--spacing--sm);
		}
		@media screen and (max-width: 576px) {
			font-size: 9vw;
		}
	}
	&___margined {
		margin: var(--spacing--lg) 0;
	}
}
