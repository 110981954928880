.HelpButton {
	min-width: 1.5em;
	position: relative;
	display: inline-block;
	text-align: center;
	border: none;
	background-color: var(--color-white);
	margin: 0 0 0.3rem 0.3rem;
	padding: 0;
	width: 1.5em;
	height: 1.5em;
	font-weight: var(--font-weight--normal);
	border-radius: 50%;
	cursor: pointer;

	&:before {
		content: "";
		border: solid 1px var(--color-primary);
		border-radius: 50%;
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}

	&:focus {
		box-shadow: none;
	}
	&:hover {
		text-decoration: none;
	}

	outline: 0;
	&:hover,
	&:focus {
		&:before {
			box-shadow: 0 0 0 var(--border--md) var(--color-white), 0 0 0 var(--border--3xl) var(--color-primary--60);
		}
	}

	&___dark {
		background-color: var(--color-primary);
		color: var(--color-white);
	}

	&___margined {
		margin: 0 0.5em;
	}

	&___big {
		min-width: 2em;
		width: 2em;
		height: 2em;
	}

	&___notClickable {
		cursor: default !important;
		display: flex;
		justify-content: center;
		align-items: center;
		&:hover,
		&:focus {
			&:before {
				box-shadow: initial;
			}
		}
	}

	&___noMargin {
		margin: 0;
	}
}

.HelpButton_button {
	width: 100%;
	height: 100%;
	padding: 0;
	border: transparent;
	background: transparent;
}
