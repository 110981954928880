@import "../../../styles/2-tools/tools.typography-mixins.scss";
@import "../../../styles/2-tools/tools.media-query.scss";
@import "../../../styles/2-tools/tools.rem.scss";
@import "../../../styles/2-tools/tools.button.scss";
@import "../../../styles/2-tools/tools.display-mixins.scss";

.RichText {
	h2 {
		@include heading--md;
		margin-bottom: var(--spacing--sm);
	}

	h3 {
		@include heading--sm;
		margin-bottom: var(--spacing--xs);
	}

	h4 {
		@include heading--xs;
		margin-bottom: var(--spacing--xs);
	}

	:global(.RichText_manchet) {
		@include manchet;
	}
	p,
	ul,
	ol,
	h1,
	h2,
	h3,
	h4 {
		max-width: var(--width-text-max);
		&[style="text-align: right;"] {
			margin-left: auto;
		}
		&[style="text-align: center;"] {
			margin-left: auto;
			margin-right: auto;
		}
	}

	p {
		@include paragraph;
		margin-bottom: var(--spacing--lg);
		white-space: normal;

		:global(.sop-app) & {
			margin-bottom: 0;
		}
	}
	p:has(+ h3, + h4, + p) {
		margin-bottom: var(--spacing--md);
	}
	&:last-child > p:last-child {
		margin-bottom: var(--spacing--sm);
	}
	:global(.RichText_arrowLink) {
		a {
			position: relative;
			margin-bottom: var(--spacing--sm);
			padding: 0 var(--spacing--md) 0 var(--spacing--xl);
			text-decoration: none;
			display: inline-block;

			&:hover,
			&:focus {
				color: var(--color-white);
				text-decoration: none;
				background-color: var(--color-primary--60);

				&:before {
					left: 0.7rem;
				}
			}

			&:before {
				content: "";
				display: block;
				position: absolute;
				left: 0;
				top: 7px;
				width: var(--spacing--sm);
				height: var(--spacing--sm);
				border: solid currentColor;
				border-width: var(--border--md) var(--border--md) 0 0;
				transform: rotate(45deg);
				transition: all 0.3s;
			}
		}
	}
	:global(.RichText_buttonLink) {
		a {
			@include cta-button(var(--color-white), var(--color-primary));
			:global(.u-bg-color--primary100) & {
				@include cta-button(var(--color-primary), var(--color-white));
			}
			:global(.u-bg-color--theme) & {
				@include cta-button(var(--color-theme-button-contrast), var(--color-theme-button));
			}
			@include cta-button-arrow;
		}
	}
	:global(.RichText_login) {
		a {
			@include cta-button(var(--color-white), var(--color-primary));
			:global(.u-bg-color--primary100) & {
				@include cta-button(var(--color-primary), var(--color-white));
			}
			:global(.u-bg-color--theme) & {
				@include cta-button(var(--color-theme-button-contrast), var(--color-theme-button));
			}
			&:before {
				mask: url("../../../icons/icon-log-in-light.svg?url");
				content: "";
				mask-repeat: no-repeat;
				mask-position: center;
				background-color: currentColor;
			}
		}
	}
	:global(.RichText_logout) {
		a {
			@include cta-button(var(--color-white), var(--color-primary));
			:global(.u-bg-color--primary100) & {
				@include cta-button(var(--color-primary), var(--color-white));
			}
			:global(.u-bg-color--theme) & {
				@include cta-button(var(--color-theme-button-contrast), var(--color-theme-button));
			}
			&:before {
				mask: url("../../../icons/icon-log-out-light.svg?url");
				content: "";
				mask-repeat: no-repeat;
				mask-position: center;
				background-color: currentColor;
			}
		}
	}
	$icon-arrow-size: 32px;
	:global(.RichText_backLink),
	:global(.RichText_forwardLink) {
		position: relative;
		display: inline-block;
		a {
			text-decoration: none;
			display: flex;
			align-items: center;
		}
	}
	:global(.RichText_backLink) {
		a {
			padding: var(--spacing--sm) var(--spacing--sm) var(--spacing--sm) calc($icon-arrow-size + var(--spacing--sm));
			&:before {
				content: url("../../../icons/icon-arrow-left.svg?url");
				position: absolute;
				left: 0;
				height: $icon-arrow-size;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
	:global(.RichText_forwardLink) {
		a {
			padding: var(--spacing--sm) calc($icon-arrow-size + var(--spacing--sm) * 2) var(--spacing--sm) 0;
			&:after {
				content: url("../../../icons/icon-arrow-right.svg?url");
				position: absolute;
				right: var(--spacing--sm);
				height: $icon-arrow-size;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	a {
		color: currentColor;
		&:hover,
		&:focus {
			color: var(--color-primary--80);
		}
	}

	blockquote {
		margin-top: var(--spacing--xl);
		margin-bottom: var(--spacing--xl);
		display: flex;
		&:before {
			content: "";
			display: block;
			min-width: pxToRem(53);
			height: pxToRem(45);
			mask: url("../../../icons/quote.svg?url");
			background: var(--color-theme-primary) no-repeat;
			margin-right: var(--spacing--sm);

			@media print {
				content: url("../../../icons/quote.svg?url");
			}
		}
		p {
			margin-top: 0;
			font-weight: var(--font-weight--black);
			@include mq("md", max) {
				font-size: var(--font-size--md);
			}
			@include mq("md") {
				font-size: var(--font-size--xl);
			}
		}
	}
	&___center {
		display: flex;
		justify-content: center;
		width: 100%;
	}

	table {
		border-collapse: collapse;
		width: 100%;
		border: var(--border--sm);
		border-color: var(--color-bg-subtle);
		margin-bottom: pxToRem(40);
		thead {
			border-top: var(--border--md) solid var(--color-black);
			border-bottom: var(--border--md) solid var(--color-black);
		}
		td,
		th {
			padding: var(--spacing--lg);
			word-break: break-word;
			hyphens: auto;
		}
		caption {
			caption-side: top;
			padding-top: var(--spacing--md);
			padding-bottom: var(--spacing--md);
			font-weight: var(--font-weight--bold);
			color: var(--color-black);
			text-align: left;
		}
		&:global(.compact) {
			td,
			th {
				padding: var(--spacing--sm);
			}
		}
		tbody {
			tr:hover,
			tr:focus {
				background-color: var(--color-bg-subtle);
			}
		}
	}
	div:last-child > table {
		margin-bottom: 0;
	}

	:global(.RichText_layoutTable) {
		border: transparent;
		:last-child(td) {
			border-bottom: var(--border--lg) double var(--color-black);
		}
		td {
			padding: var(--spacing--sm) 0;
		}
		tr > td:last-child {
			text-align: end;
		}
		tr:last-child {
			border-top: var(--border--md) solid var(--color-black);
			border-bottom: var(--border--xl) double var(--color-black);
		}
	}

	:global(.RichText_smallText) {
		font-size: var(--font-size--xs);
	}

	ul,
	ol {
		max-width: 100%;
		padding-left: var(--spacing--2xl);
		line-height: var(--line-height--2xl);
		list-style-position: outside;
		margin-top: var(--spacing--sm);
		margin-bottom: var(--spacing--lg);
		li {
			max-width: var(--width-text-max);
			padding-left: var(--spacing--sm);
			&[style*="text-align: center;"] {
				text-align: left !important;
				margin: 0 auto 0 auto;
				position: relative;
				left: pxToRem(21);
			}
		}
		&:has(li[style*="text-align: center;"]) {
			padding-left: 0;
		}
	}

	ol[style*="list-style-type: forloeb-med-flag;"] {
		counter-reset: section;
		padding-left: pxToRem(20);
		margin: var(--spacing--md) 0 pxToRem(40) 0;
		li {
			list-style-type: none;
			position: relative;
			border-left: var(--border--sm) dotted var(--color-primary--60);
			padding: pxToRem(3) 0 var(--spacing--2xl) var(--spacing--2xl);
		}

		li:last-child {
			border-left: none;
			padding-bottom: 0;
		}

		li::before {
			width: pxToRem(40);
			height: pxToRem(40);
			background: var(--color-white);
			border-radius: 50%;
			position: absolute;
			left: pxToRem(-21);
			top: pxToRem(-4);
			color: var(--color-primary--60);
			display: flex;
			justify-content: center;
			align-items: center;
			border: var(--border--sm) solid var(--color-primary--60);
			counter-increment: section;
			content: counter(section);
		}

		li:last-child::before {
			content: "";
			text-align: center;
			background: var(--color-white) no-repeat url("../../../icons/finish.svg?url") 50%;
			background-size: 15px;
		}
	}

	ol[style*="list-style-type: forloeb-uden-flag;"] {
		counter-reset: section;
		padding-left: pxToRem(20);
		margin: var(--spacing--md) 0 pxToRem(40) 0;
		li {
			list-style-type: none;
			position: relative;
			border-left: var(--border--sm) dotted var(--color-primary--60);
			padding: pxToRem(3) 0 var(--spacing--2xl) var(--spacing--2xl);
		}

		li:last-child {
			border-left: none;
			padding-bottom: 0;
		}

		li::before {
			width: pxToRem(40);
			height: pxToRem(40);
			background: var(--color-white);
			border-radius: 50%;
			position: absolute;
			left: pxToRem(-21);
			top: pxToRem(-4);
			color: var(--color-primary--60);
			display: flex;
			justify-content: center;
			align-items: center;
			border: var(--border--sm) solid var(--color-primary--60);
			counter-increment: section;
			content: counter(section);
		}
	}

	ol[style*="list-style-type: fremhaevede-tal;"] {
		counter-reset: section;
		padding-left: pxToRem(21);
		margin: var(--spacing--md) 0 pxToRem(40) 0;
		li {
			list-style-type: none;
			position: relative;
			padding: pxToRem(3) 0 var(--spacing--2xl) var(--spacing--2xl);
		}

		li:last-child {
			padding-bottom: 0;
		}

		li::before {
			@include heading--md;
			width: pxToRem(40);
			height: pxToRem(40);
			background: var(--color-white);
			border-radius: 50%;
			position: absolute;
			left: pxToRem(-21);
			top: pxToRem(-4);
			display: flex;
			justify-content: center;
			align-items: center;
			border: var(--border--sm) solid var(--color-primary--60);
			counter-increment: section;
			content: counter(section);
		}
	}
}
