@import "../../../../styles/2-tools/tools.behavior.scss";
@import "../../../../styles/2-tools/tools.button.scss";

// Button
.Button {
	cursor: pointer;
	font-weight: var(--font-weight--semibold);
	display: flex;
	align-items: center;
	padding: var(--spacing--md);
	text-decoration: none;
	line-height: 1.5;
	text-align: center;
	justify-content: center;
	min-width: 5rem;

	&___medium {
		min-height: 44px;
		padding: var(--spacing--sm);
		min-width: 3.125rem;
	}
	&___small {
		padding: 0;
		min-width: 3.125rem;
		line-height: 1;
		z-index: 3; // Set according to input field z-index
	}

	&___fontWeightnormal {
		font-weight: var(--font-weight--normal);
	}

	@include transition {
		transition: all var(--trans-time--shorter);
	}

	@include button-disabled-style();

	&___primary-dark {
		@include button-colors-primary(var(--color-primary), var(--color-white), var(--color-white));
	}
	&___primary-light {
		@include button-colors-primary(var(--color-white), var(--color-primary), var(--color-primary));
	}
	&___primary-theme {
		@include button-colors-primary(var(--color-theme-button), var(--color-theme-button-contrast), var(--color-theme-primary));
	}
	&___secondary-dark {
		@include button-colors-secondary(var(--color-primary), var(--color-white));
	}
	&___secondary-light {
		@include button-colors-secondary(var(--color-white), var(--color-primary));
	}
	&___secondary-theme {
		@include button-colors-secondary(var(--color-theme-button), var(--color-theme-primary));
	}
}
