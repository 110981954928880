@import "../../../../styles/2-tools/tools.action.scss";
@import "../../../../styles/2-tools/tools.media-query.scss";

.IconButton {
	display: flex;
	@include mq("md", max) {
		font-size: var(--font-size--2xs);
	}
}

.IconButton_button {
	.IconButton___highlighted & {
		display: flex;
		align-items: center;
		gap: var(--spacing--md);
		padding: var(--spacing--xs) var(--spacing--md) var(--spacing--xs) 0;
		transition: all 0.5s ease-in-out;
		color: var(--color-primary);
		border-radius: var(--corner-size--md);

		&:hover,
		&:focus {
			transform: translateX(var(--spacing--sm));
			padding: var(--spacing--xs) var(--spacing--md) var(--spacing--xs) var(--spacing--xs);
			background: var(--color-primary--60);
			color: var(--color-white);
		}
	}
	@include mq("md", max) {
		display: block;
	}
}

.IconButton_icon {
	margin-right: var(--spacing--sm);
}
