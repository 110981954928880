@import "../../../../styles/2-tools/tools.rem.scss";

.Picture {
	position: relative;
	display: block;

	&___fullHeight {
		height: 100%;
	}

	&___fullWidth {
		width: 100%;
	}

	&___padding {
		&small {
			padding: var(--spacing--lg);
		}
		&large {
			padding: var(--spacing--2xl);
		}
	}
}

.PictureImage {
	width: 100%;
	height: 100%;
	object-fit: contain;

	&___border {
		border: var(--border--sm) solid var(--color-primary);
	}


	&___cover {
		object-fit: cover;
	}
}
