.DownloadButton {
	margin-top: var(--spacing--lg);
	margin-bottom: var(--spacing--xl);
	background-color: var(--color-white);
}

.DownloadButton_button {
	text-decoration: none;
	display: inline-block;
	&:hover,
	&:focus {
		text-decoration: none;
	}
	&___spacing {
		margin-top: var(--spacing--md);
	}
}

.DownloadButton_buttonText {
	margin-left: var(--spacing--xs);
	.DownloadButton:hover &,
	.DownloadButton:focus & {
		text-decoration: underline;
	}
}
