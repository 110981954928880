@import "../../../../styles/2-tools/tools.hover-mixins.scss";

.AlternativeButton {
	border: none;
	background-color: transparent;
	padding: 0;
	display: flex;
	align-items: center;

	&:hover {
		color: var(--color-primary--60);
		text-decoration: underline;
	}

	&:focus {
		@include hoverFocusShadow;
	}

	&___flipped {
		flex-direction: row-reverse;
	}
}

.AlternativeButton_icon {
	align-items: center;
	background-color: var(--color-primary);
	border-radius: 50%;
	color: var(--color-white);
	display: inline-flex;
	height: var(--icon-size--md);
	justify-content: center;
	width: var(--icon-size--md);

	&___darkMode {
		background-color: var(--color-white);
		color: var(--color-primary);
	}

	.AlternativeButton:hover &,
	.AlternativeButton:focus & {
		background-color: var(--color-primary--60);

		&___darkMode {
			color: var(--color-white);
		}
	}
}

.AlternativeButton_text {
	&:not(&___flipped) {
		margin-left: var(--spacing--sm);
	}

	&___flipped {
		margin-right: var(--spacing--sm);
	}

	&___darkMode {
		color: var(--color-white);
	}

	.AlternativeButton:hover &,
	.AlternativeButton:focus & {
		&___darkMode {
			text-decoration-color: var(--color-white);
		}
	}
}
