.Video {
	width: 100%;
	&___placeholder {
		background-color: var(--color-bg--light-blue);
		&.Video___16-9 {
			aspect-ratio: 16/9;
		}
		&.Video___1-1 {
			aspect-ratio: 1/1;
		}
		&.Video___9-16 {
			aspect-ratio: 9/16;
		}
	}
}

.Video_embed {
	max-width: 100%;
	.Video___16-9 & {
		aspect-ratio: 16/9;
	}
	.Video___1-1 & {
		aspect-ratio: 1/1;
	}
	.Video___9-16 & {
		aspect-ratio: 9/16;
	}
}

.Video_Frame {
	border: none;
	width: 100%;
	height: 100%;
	.Video___16-9 & {
		aspect-ratio: 16/9;
	}
	.Video___1-1 & {
		aspect-ratio: 1/1;
	}
	.Video___9-16 & {
		aspect-ratio: 9/16;
	}
}
